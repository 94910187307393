<template>
    <div class="instructor-details-desc">
        <div v-if="classrooms.length" class="row justify-content-center">
            <div v-for="classroom in classrooms" :key="classroom.id" class="col-md-4 pt-2">
                <div class="single-instructor-member mb-30 shadow-lg">
                    <router-link :to="{ name: 'TrainerClassRoomDashboard', params: {id: classroom.id}}">
                        <div class="member-image text-default">
                            <svg height="100" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path></svg>
                        </div>
                        <div class="member-content">
                            <h5 class="card-title">{{ classroom.name }}</h5>
                            <p class="card-text">
                                {{ classroom.users_count }} Trainees
                            </p>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="text-center alert alert-danger" v-else>
            <h3>You are not invited in classroom</h3>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return {
            isLoading: true,
            token: localStorage.getItem('user_data'),
            classrooms: {},
            errors: {},        
        }
    },
    methods:{
        getClassrooms(){
            axios.get('https://apitraining.vipawaworks.com/api/classroom/get_trainee_class_rooms').then(response => {
                this.classrooms = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        }
    },
    created(){
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getClassrooms() 
    }
}
</script>

<style>

</style>