<template>
    <div v-if="!isLoading">
        <div class="instructor-details-desc">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end pb-10">
                <router-link :to="{ name: 'TrainerAddClassRoom'}" class="btn default-btn">New Class Room</router-link>
            </div>
            <div v-if="classrooms.length" class="row justify-content-center">
                <div class="col-md-4 pt-2" v-for="classroom in classrooms" :key="classroom.id">
                    <div class="single-instructor-member mb-30 shadow-lg">
                        <router-link :to="{ name: 'TrainerClassRoomDashboard', params: {id: classroom.id}}">
                            <div class="member-image text-default">
                                <svg height="100" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
                            </div>
                            <div class="member-content">
                                <h6><router-link :to="{ name: 'TrainerClassRoomDashboard', params: {id: classroom.id}}">{{ classroom.name }}</router-link></h6>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="text-center" v-else>
                <h3>You have no Class Room</h3>
                <router-link :to="{ name: 'TrainerAddClassRoom'}" class="btn default-btn">New Class Room</router-link>
            </div>
        </div>
    </div>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import Loading from '@/components/Loading.vue'
export default {
    components: { Loading, },
    data(){
        return {
            isLoading: true,
            token: localStorage.getItem('user_data'),
            classrooms: {},
            errors: {},        
        }
    },
    methods:{
        getClassrooms(){
            axios.get('https://apitraining.vipawaworks.com/api/classroom/get_company_class_rooms').then(response => {
                this.classrooms = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
        deleteCourse(id) {
                Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ff1949',
                cancelButtonColor: '#ffc107',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('https://apitraining.vipawaworks.com/api/course/delete/'+ id)
                    .then(response => {
                        response.data
                        this.classrooms = this.classrooms.filter(course => {
                        return course.id != id
                        })
                    }).catch((errors) => {
                        this.errors = errors.response.data.errors
                    })
                    Swal.fire(
                    'Deleted!',
                    'Specialist has been Deleted.',
                    'success'
                    )
                }
                })
            },
    },
    created(){
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getClassrooms() 
    }
}
</script>

<style>

</style>